.Menu {

  padding: 16px;
  padding-bottom: 0;
  display: flex;
  gap: 30px;
  font-size: 32px;
  font-family: 'main-text-bold', sans-serif;
  
  a {
    color: var(--fg-white);
    transition: all 0.2s ease;

    &:hover {
      border-bottom: 3px solid var(--fg-white);
    }
  }



  // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}