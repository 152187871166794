.Section {

  background-color: var(--bg-white);
  padding: 40px 0;

  .section-content {
    h1, h2, {
      text-align: center;
      font-weight: bold;
      margin: 20px;
    }

    h3 { font-size: 28px; font-weight: bold; margin-top: 20px;}
  }

  .section-content-dark {
    margin: 40px 0;

    display: flex;
    align-items: flex-start;
    gap: 40px;

    h1, h2, h3, h4 {
      text-align: left;
      color: var(--fg-white);
    }

    p {
      color: var(--fg-white-muted);
    }
  }

  &[data-object-type='info'] {
    background-color: var(--bg-slate-gray);
  }

  &[data-object-type='form'] {
    background-color: var(--bg-slate);
  }

  // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}