.SubscribeNewsletter {

  .input-group {
    margin-top: 10px;
  }

  input {
    height: 40px;
    border-radius: 0! important;
    padding: 2px;
    width: 80%;

  }

  button {
    background-color: var(--bg-highlight-green);
    border-radius: 0! important;
    margin-left: -2px;
    height: 40px;
    border: none !important;
    width: 40px;
  }

  // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}