.Button {

  background-color: var(--bg-highlight-green);
  padding: 14px 27px;
  text-align: center;
  color: var(--fg-white);
  display: inline-block;
  font-size: 18px;
  margin: 0 0 13px 10px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #7e7e12;
  }
  
  // XS - Phone;
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}