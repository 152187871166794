.Footer {

  width: 100%;
  padding: 40px 0;
  background-color: var(--bg-flat-gray);

  h3 {
    color: var(--fg-white);
  }

  // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}