.Header {

  padding: 40px 0;

  .slogan {
    display: flex;
    height: 80vh;
    font-size: 48px;
    text-align: right;
    font-family: 'main-text-bold', serif;
    font-weight: bold;
    color: var(--fg-white);
    align-items: center;
    line-height: 48px;
  }

  // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}