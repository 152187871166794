.FooterColumn {

  h3 {
    color: var(--fg-white);
    margin-bottom: 10px;
  }

  ul { list-style-type: none}
  ul li {
    color: var(--fg-white-muted);
    font-size: 22px;
    margin-left: -30px;
    margin-right: 0;
  }

  a {
    transition: all 0.3s ease;
  }

  a:hover {
    text-decoration: underline;
  }


}