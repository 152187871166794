/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
a {
  text-decoration: none;
}

/** FONTS **/
/*! main-text-regular */
@font-face {
  font-family: "main-text-regular";
  src: url("../fonts/phenomena-regular-webfont.woff") format("woff"), url("../fonts/phenomena-regular-webfont.woff2") format("woff2");
}
/*! main-text-bold */
@font-face {
  font-family: "main-text-bold";
  src: url("../fonts/phenomena-bold-webfont.woff") format("woff"), url("../fonts/phenomena-bold-webfont.woff2") format("woff2");
}
/*! main-text-link */
@font-face {
  font-family: "main-text-link";
  src: url("../fonts/phenomena-regular-webfont.woff") format("woff"), url("../fonts/phenomena-regular-webfont.woff2") format("woff2");
}
/*! label-text */
@font-face {
  font-family: "label-text";
  src: url("../fonts/phenomena-regular-webfont.woff") format("woff"), url("../fonts/phenomena-regular-webfont.woff2") format("woff2");
}
/*! heading */
@font-face {
  font-family: "heading";
  src: url("../fonts/phenomena-bold-webfont.woff") format("woff"), url("../fonts/phenomena-bold-webfont.woff2") format("woff2");
}
/** CUSTOM PROPERTIES **/
:root {
  /** COLOR **/
  /*! Background Color */
  /*! black */
  --bg-black: #161616;
  /*! highlight-black */
  --bg-highlight-black: #212121;
  /*! black-muted */
  --bg-black-muted: #777;
  /*! white */
  --bg-white: #FFF;
  /*! white-muted */
  --bg-white-muted: rgba(255, 255, 255, 0.4);
  /*! highlight-green */
  --bg-highlight-green: #adad13;
  --bg-highlight-green-light-10: #dbdb18;
  --bg-highlight-green-light-20: #eaea3c;
  --bg-highlight-green-light-30: #efef6a;
  --bg-highlight-green-light-40: #f4f498;
  --bg-highlight-green-light-50: #f9f9c6;
  --bg-highlight-green-dark-10: #7f7f0e;
  --bg-highlight-green-dark-20: #515109;
  --bg-highlight-green-dark-30: #232304;
  --bg-highlight-green-dark-40: black;
  --bg-highlight-green-dark-50: black;
  /*! slate */
  --bg-slate: rgb(239, 239, 239);
  /*! slate-gray */
  --bg-slate-gray: #1E2426;
  /*! flat-gray */
  --bg-flat-gray: rgb(60, 60, 60);
  /*! Foreground Color */
  /*! black */
  --fg-black: #161616;
  /*! highlight-black */
  --fg-highlight-black: #212121;
  /*! black-muted */
  --fg-black-muted: #777;
  /*! white */
  --fg-white: #FFF;
  /*! white-muted */
  --fg-white-muted: rgba(255, 255, 255, 0.4);
  /*! highlight-green */
  --fg-highlight-green: #adad13;
  --fg-highlight-green-light-10: #dbdb18;
  --fg-highlight-green-light-20: #eaea3c;
  --fg-highlight-green-light-30: #efef6a;
  --fg-highlight-green-light-40: #f4f498;
  --fg-highlight-green-light-50: #f9f9c6;
  --fg-highlight-green-dark-10: #7f7f0e;
  --fg-highlight-green-dark-20: #515109;
  --fg-highlight-green-dark-30: #232304;
  --fg-highlight-green-dark-40: black;
  --fg-highlight-green-dark-50: black;
  /*! slate */
  --fg-slate: rgb(239, 239, 239);
  /*! slate-gray */
  --fg-slate-gray: #1E2426;
  /*! flat-gray */
  --fg-flat-gray: rgb(60, 60, 60);
  /** FONT **/
  --main-text-regular: "main-text-regular";
  --main-text-bold: "main-text-bold";
  --main-text-link: "main-text-link";
  --label-text: "label-text";
  --background-transition: background 0.4s ease;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-white);
  font-family: "main-text-regular", sans-serif;
}

a {
  color: var(--fg-highlight-green);
}

/** UTILITY: Colors **/
/*! Background Color */
/*! black */
.bg-black {
  background-color: var(--bg-black);
}

/*! highlight-black */
.bg-highlight-black {
  background-color: var(--bg-highlight-black);
}

/*! black-muted */
.bg-black-muted {
  background-color: var(--bg-black-muted);
}

/*! white */
.bg-white {
  background-color: var(--bg-white);
}

/*! white-muted */
.bg-white-muted {
  background-color: var(--bg-white-muted);
}

/*! highlight-green */
.bg-highlight-green {
  background-color: var(--bg-highlight-green);
}

.bg-highlight-green-light-10 {
  background-color: var(--bg-highlight-green-light-10);
}

.bg-highlight-green-light-20 {
  background-color: var(--bg-highlight-green-light-20);
}

.bg-highlight-green-light-30 {
  background-color: var(--bg-highlight-green-light-30);
}

.bg-highlight-green-light-40 {
  background-color: var(--bg-highlight-green-light-40);
}

.bg-highlight-green-light-50 {
  background-color: var(--bg-highlight-green-light-50);
}

.bg-highlight-green-dark-10 {
  background-color: var(--bg-highlight-green-dark-10);
}

.bg-highlight-green-dark-20 {
  background-color: var(--bg-highlight-green-dark-20);
}

.bg-highlight-green-dark-30 {
  background-color: var(--bg-highlight-green-dark-30);
}

.bg-highlight-green-dark-40 {
  background-color: var(--bg-highlight-green-dark-40);
}

.bg-highlight-green-dark-50 {
  background-color: var(--bg-highlight-green-dark-50);
}

/*! slate */
.bg-slate {
  background-color: var(--bg-slate);
}

/*! slate-gray */
.bg-slate-gray {
  background-color: var(--bg-slate-gray);
}

/*! flat-gray */
.bg-flat-gray {
  background-color: var(--bg-flat-gray);
}

/*! Foreground Color */
/*! black */
.fg-black {
  color: var(--fg-black);
}

/*! highlight-black */
.fg-highlight-black {
  color: var(--fg-highlight-black);
}

/*! black-muted */
.fg-black-muted {
  color: var(--fg-black-muted);
}

/*! white */
.fg-white {
  color: var(--fg-white);
}

/*! white-muted */
.fg-white-muted {
  color: var(--fg-white-muted);
}

/*! highlight-green */
.fg-highlight-green {
  color: var(--fg-highlight-green);
}

.fg-highlight-green-light-10 {
  color: var(--fg-highlight-green-light-10);
}

.fg-highlight-green-light-20 {
  color: var(--fg-highlight-green-light-20);
}

.fg-highlight-green-light-30 {
  color: var(--fg-highlight-green-light-30);
}

.fg-highlight-green-light-40 {
  color: var(--fg-highlight-green-light-40);
}

.fg-highlight-green-light-50 {
  color: var(--fg-highlight-green-light-50);
}

.fg-highlight-green-dark-10 {
  color: var(--fg-highlight-green-dark-10);
}

.fg-highlight-green-dark-20 {
  color: var(--fg-highlight-green-dark-20);
}

.fg-highlight-green-dark-30 {
  color: var(--fg-highlight-green-dark-30);
}

.fg-highlight-green-dark-40 {
  color: var(--fg-highlight-green-dark-40);
}

.fg-highlight-green-dark-50 {
  color: var(--fg-highlight-green-dark-50);
}

/*! slate */
.fg-slate {
  color: var(--fg-slate);
}

/*! slate-gray */
.fg-slate-gray {
  color: var(--fg-slate-gray);
}

/*! flat-gray */
.fg-flat-gray {
  color: var(--fg-flat-gray);
}

.main-text-regular-12 {
  font-family: "main-text-regular";
  font-size: 0.75rem;
}

.main-text-regular-14 {
  font-family: "main-text-regular";
  font-size: 0.875rem;
}

.main-text-regular-16 {
  font-family: "main-text-regular";
  font-size: 1rem;
}

.main-text-regular-18 {
  font-family: "main-text-regular";
  font-size: 1.125rem;
}

.main-text-regular-20 {
  font-family: "main-text-regular";
  font-size: 1.25rem;
}

.main-text-regular-22 {
  font-family: "main-text-regular";
  font-size: 1.375rem;
}

.main-text-bold-12 {
  font-family: "main-text-bold";
  font-size: 0.75rem;
}

.main-text-bold-14 {
  font-family: "main-text-bold";
  font-size: 0.875rem;
}

.main-text-bold-16 {
  font-family: "main-text-bold";
  font-size: 1rem;
}

.main-text-bold-18 {
  font-family: "main-text-bold";
  font-size: 1.125rem;
}

.main-text-bold-20 {
  font-family: "main-text-bold";
  font-size: 1.25rem;
}

.main-text-bold-22 {
  font-family: "main-text-bold";
  font-size: 1.375rem;
}

.main-text-link-12 {
  font-family: "main-text-link";
  font-size: 0.75rem;
}

.main-text-link-14 {
  font-family: "main-text-link";
  font-size: 0.875rem;
}

.main-text-link-16 {
  font-family: "main-text-link";
  font-size: 1rem;
}

.main-text-link-18 {
  font-family: "main-text-link";
  font-size: 1.125rem;
}

.main-text-link-20 {
  font-family: "main-text-link";
  font-size: 1.25rem;
}

.main-text-link-22 {
  font-family: "main-text-link";
  font-size: 1.375rem;
}

.label-text-12 {
  font-family: "label-text";
  font-size: 0.75rem;
}

.label-text-14 {
  font-family: "label-text";
  font-size: 0.875rem;
}

.label-text-16 {
  font-family: "label-text";
  font-size: 1rem;
}

.label-text-18 {
  font-family: "label-text";
  font-size: 1.125rem;
}

.label-text-20 {
  font-family: "label-text";
  font-size: 1.25rem;
}

.label-text-22 {
  font-family: "label-text";
  font-size: 1.375rem;
}

.label-text-24 {
  font-family: "label-text";
  font-size: 1.5rem;
}

.label-text-26 {
  font-family: "label-text";
  font-size: 1.625rem;
}

.label-text-28 {
  font-family: "label-text";
  font-size: 1.75rem;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.is-flex {
  display: flex;
}

.is-row {
  flex-direction: row;
}

.is-column {
  flex-direction: column;
}

.float-right {
  justify-content: flex-end;
}

.float-start {
  justify-content: flex-start;
}

.float-center {
  justify-content: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

h1 {
  font-family: "heading";
  font-size: 2.625rem;
}

h2 {
  font-family: "heading";
  font-size: 2.125rem;
}

h3 {
  font-family: "heading";
  font-size: 1.5rem;
}

h4 {
  font-family: "heading";
  font-size: 1.625rem;
}

h5 {
  font-family: "heading";
  font-size: 1.5rem;
}

h6 {
  font-family: "heading";
  font-size: 1.375rem;
}

.Button {
  background-color: var(--bg-highlight-green);
  padding: 14px 27px;
  text-align: center;
  color: var(--fg-white);
  display: inline-block;
  font-size: 18px;
  margin: 0 0 13px 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.Button:hover {
  background-color: #7e7e12;
}
.Logo {
  height: auto;
  width: 220px;
}
.Logo[data-object-type=home] {
  width: 420px;
}
.ReadMore a {
  color: var(--fg-highlight-green);
  font-family: "main-text-bold", sans-serif;
  font-size: 22px;
}
.Paragraph {
  font-size: 22px;
}
.InputField {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
}
.TextArea {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
}
.ThankYou {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: var(--bg-highlight-green);
}
.ProductItem {
  padding: 5px;
  width: 100%;
}
.ProductItem img {
  min-width: 100%;
  width: 100%;
  height: auto;
}
.OptionItem {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  cursor: pointer;
}
.Banner {
  height: 40vh;
}
.Banner[data-object-type=home] {
  height: 100vh;
}
.FooterColumn h3 {
  color: var(--fg-white);
  margin-bottom: 10px;
}
.FooterColumn ul {
  list-style-type: none;
}
.FooterColumn ul li {
  color: var(--fg-white-muted);
  font-size: 22px;
  margin-left: -30px;
  margin-right: 0;
}
.FooterColumn a {
  transition: all 0.3s ease;
}
.FooterColumn a:hover {
  text-decoration: underline;
}

.SubscribeNewsletter .input-group {
  margin-top: 10px;
}
.SubscribeNewsletter input {
  height: 40px;
  border-radius: 0 !important;
  padding: 2px;
  width: 80%;
}
.SubscribeNewsletter button {
  background-color: var(--bg-highlight-green);
  border-radius: 0 !important;
  margin-left: -2px;
  height: 40px;
  border: none !important;
  width: 40px;
}
.Menu {
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  gap: 30px;
  font-size: 32px;
  font-family: "main-text-bold", sans-serif;
}
.Menu a {
  color: var(--fg-white);
  transition: all 0.2s ease;
}
.Menu a:hover {
  border-bottom: 3px solid var(--fg-white);
}
.Footer {
  width: 100%;
  padding: 40px 0;
  background-color: var(--bg-flat-gray);
}
.Footer h3 {
  color: var(--fg-white);
}
.Section {
  background-color: var(--bg-white);
  padding: 40px 0;
}
.Section .section-content h1, .Section .section-content h2 {
  text-align: center;
  font-weight: bold;
  margin: 20px;
}
.Section .section-content h3 {
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
}
.Section .section-content-dark {
  margin: 40px 0;
  display: flex;
  align-items: flex-start;
  gap: 40px;
}
.Section .section-content-dark h1, .Section .section-content-dark h2, .Section .section-content-dark h3, .Section .section-content-dark h4 {
  text-align: left;
  color: var(--fg-white);
}
.Section .section-content-dark p {
  color: var(--fg-white-muted);
}
.Section[data-object-type=info] {
  background-color: var(--bg-slate-gray);
}
.Section[data-object-type=form] {
  background-color: var(--bg-slate);
}
.Header {
  padding: 40px 0;
}
.Header .slogan {
  display: flex;
  height: 80vh;
  font-size: 48px;
  text-align: right;
  font-family: "main-text-bold", serif;
  font-weight: bold;
  color: var(--fg-white);
  align-items: center;
  line-height: 48px;
}
.ProductList {
  margin-bottom: 80px;
}
.ProductList .ProductColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  cursor: pointer;
}
.ProductList .spinner-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ContactForm {
  padding-top: 80px;
  padding-bottom: 80px;
}
.ContactForm .float-right {
  display: flex;
  align-items: flex-end;
}

